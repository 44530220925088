import { ReactNode } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { ControlledToggle } from '../toggle';

const SideMenuItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  padding: 0 28px;

  ${down('md')} {
    padding: 0 0 0 16px;
  }
`;

const SideMenuItemInfo = styled.div`
  display: flex;
  gap: 16px;

  ${down('md')} {
    gap: 8px;
  }

  p {
    margin: 8px 0 0;
    color: ${({ theme }) => theme.colors.dark[40]};
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }
`;

const SideMenuItemIcon = styled.div`
  font-size: 24px;
`;

const SideMenuItemLabel = styled.label`
  line-height: 24px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-family: 'Nunito', sans-serif;
`;

interface SideMenuItemProps {
  name: string;
  label: string;
  description: string;
  icon: ReactNode;
}

export function SideMenuItem({ name, label, description, icon }: SideMenuItemProps) {
  return (
    <SideMenuItemWrapper>
      <SideMenuItemInfo>
        <SideMenuItemIcon>{icon}</SideMenuItemIcon>
        <div>
          <SideMenuItemLabel>{label}</SideMenuItemLabel>
          <p
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </SideMenuItemInfo>
      <div>
        <ControlledToggle name={name} />
      </div>
    </SideMenuItemWrapper>
  );
}
