import { ReactNode, useEffect, useRef, useState } from 'react';
import { down } from 'styled-breakpoints';
import { createGlobalStyle } from 'styled-components';
import { Breakpoints, GatewayTheme, styled, useBreakpoints } from '@use-gateway/theme';
import { UnknownFunction } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';
import { Footer } from '../footer';
import { BottomBar, MobilePanelHeader, PanelHeader, Sidebar } from '../panel';

const PanelLayoutWrapper = styled.div`
  width: calc(100% - 64px);
  max-width: 1200px;
  margin: 0 auto;

  ${down('sm')} {
    width: calc(100% - 16px);
  }

  button.download {
    width: 45px;
    height: 45px;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    background: none;

    cursor: pointer;

    color: ${({ theme }) => theme.colors.primary};

    ${styledTransition(['color'], 150)}

    svg {
      font-size: 20px;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.light[70]};
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;

const PanelLayoutMain = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface PanelLayoutContentWrapperProps {
  heightActions: number | null;
  isHiddenBottomBar?: boolean;
}

const PanelLayoutContentWrapper = styled.div<PanelLayoutContentWrapperProps>`
  display: flex;
  padding-bottom: 40px;

  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.6;
    margin: 0;
  }

  label {
    display: flex;
    align-items: center;

    svg {
      font-size: 36px;
    }
  }

  ${down('sm')} {
    position: fixed;
    left: 8px;
    right: 8px;
    top: ${({ heightActions }) => (heightActions ? `${60 + 16 + heightActions}px` : '70px')};
    bottom: ${({ isHiddenBottomBar }) => (isHiddenBottomBar ? '16px' : '76px')};

    overflow-y: auto;

    margin-top: 16px;
    padding-bottom: 0;
  }
`;

export const PanelMobileActionBar = styled.div`
  position: fixed;
  margin-top: 12px;
  left: 8px;
  right: 8px;
  top: 80px;

  color: ${({ theme }) => theme.colors.light[100]};

  &,
  & > [data-bedrock-padbox] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > [data-bedrock-padbox] {
    width: 100%;
  }
`;

const GlobalOverwrites = createGlobalStyle`
  ${down('sm')} {
    body {
      background-color: ${({ theme }) => (theme as GatewayTheme).colors.light[80]};
    }
  }
`;

export interface PanelLayoutProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  actions?: ReactNode;
  isHiddenBottomBar?: boolean;
  onBack?: UnknownFunction;
  isClose?: boolean;
  mobileWhiteBg?: boolean;
}
export function PanelLayout({
  children,
  title,
  subtitle,
  actions,
  isHiddenBottomBar,
  onBack,
  isClose,
  mobileWhiteBg,
}: PanelLayoutProps) {
  const { isUp, isDown, screenSize } = useBreakpoints();
  const [heightActions, setHeightActions] = useState<number | null>(null);
  const divRefActions = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRefActions.current) {
      setHeightActions(divRefActions.current.clientHeight);
    }
  }, [divRefActions, screenSize]);

  return (
    <PanelLayoutWrapper>
      {!mobileWhiteBg && <GlobalOverwrites />}
      {isUp(Breakpoints.sm) ? (
        <PanelHeader />
      ) : (
        <>
          <MobilePanelHeader title={title} subtitle={subtitle} onBack={onBack} isClose={isClose} />
          {actions && <PanelMobileActionBar ref={divRefActions}>{actions}</PanelMobileActionBar>}
        </>
      )}
      <PanelLayoutContentWrapper
        heightActions={heightActions}
        isHiddenBottomBar={isHiddenBottomBar}>
        {isUp(Breakpoints.sm) && <Sidebar />}
        <PanelLayoutMain>
          {children}
          {isUp(Breakpoints.sm) && <Footer marginTop={32} />}
        </PanelLayoutMain>
      </PanelLayoutContentWrapper>
      {isDown(Breakpoints.sm) && !isHiddenBottomBar && <BottomBar />}
    </PanelLayoutWrapper>
  );
}
