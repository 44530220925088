import getConfig from 'next/config';
import { Link } from '@use-gateway/components';
import { IconWallet, IconPayments, IconAnnouncementSpeaker, IconRequest } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { ProjectNames } from '@use-gateway/types';
import { styledTransition, useAuth, useNoticeable } from '@use-gateway/utils';

const SidebarWrapper = styled.aside`
  width: 260px;
  display: flex;
  flex-direction: column;
  margin-right: 21px;

  a {
    position: relative;
    width: calc(100% - 80px);
    padding: 12px 22px 12px 58px;
    border-radius: ${({ theme }) => theme.borderRadius.sm};

    font-weight: 500;
    font-size: 14px;
    line-height: 1.42;
    /* identical to box height, or 143% */

    display: flex;
    align-items: flex-end;
    letter-spacing: -0.01em;

    /* Primary/100 */

    color: ${({ theme }) => theme.colors.dark[100]};
    text-decoration: none;

    cursor: pointer;

    ${styledTransition(['color', 'background-color'])}

    svg {
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
    }

    &.active {
      background-color: ${({ theme }) => theme.colors.light[80]};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Bage = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.light[100]};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  font-size: 0.75rem;
`;

const { publicRuntimeConfig } = getConfig();
const { PROJECT_NAME } = publicRuntimeConfig;

export function Sidebar() {
  const { user } = useAuth();
  const { newsCount, toggleWidget } = useNoticeable();

  return (
    <>
      <SidebarWrapper>
        <Link href={'/wallet/'}>
          <a>
            <IconWallet />
            Wallet
          </a>
        </Link>
        <Link href={'/transactions/'}>
          <a>
            <IconPayments />
            Transactions
          </a>
        </Link>
        {user?.menu_points.withdrawal_requests && (
          <Link href={'/requests/'}>
            <a>
              <IconRequest />
              Requests
            </a>
          </Link>
        )}
        {PROJECT_NAME === ProjectNames.UseGateway && user?.menu_points.news && (
          <a onClick={toggleWidget}>
            <IconAnnouncementSpeaker />
            What&apos;s new {!!newsCount && <Bage>{newsCount}</Bage>}
          </a>
        )}
      </SidebarWrapper>
    </>
  );
}
